import React from 'react';
import { Link, Grid, Avatar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Item from '@material-ui/core/Grid';
// import profilePicture from '../images/avatar.jpg';

const content = () => (
    <Grid container spacing={2} alignItems="center" justifyContent="center" direction="row" itemType="http://schema.org/Person">
        <Grid item xs={6} sm={4} md={3} lg={2}>
            <Item><Avatar src="avatar.jpg" alt="Avatar" style={{ height: 'auto', width: '100%', maxHeight: '100%'}} itemProp="image" /></Item>
        </Grid>
        <Grid item sm>
            <Typography variant="body1">
                I am a PhD student at McGill University and Mila, where I work on <i>conversational web navigation</i> under the supervision of 
                &thinsp;<Link color="secondary" href="https://sivareddy.in/" target="_blank">Dr. Siva Reddy</Link>.
                During my studies, I visited ServiceNow Research, where I worked with Dr. Harm de Vries on conversational table retrieval.
                Previously, I lead various ML initiatives and developed open-source libraries at Plotly, worked on summarization 
                engines at Deloitte, and researched ML for taxation policy at the McGill Clinical and Health Informatics lab.
            </Typography>
        </Grid>
    </Grid>
);

export default content;
