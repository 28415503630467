import React, { Suspense } from 'react';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';

import AboutMe from './content/AboutMe';
import ContactIntro from './content/ContactIntro';
import ResearchIntro from './content/ResearchIntro';

const Sidebar = React.lazy(() => import('./components/Sidebar'));
const TwitterDeck = React.lazy(() => import('./components/TwitterDeck'));
const ProjectSwipeView = React.lazy(() => import('./components/ProjectSwipeView'));
const ExperienceTimeline = React.lazy(() => import('./components/ExperienceTimeline'));
const Navbar = React.lazy(() => import('./components/Navbar'));
const ContactForm = React.lazy(() => import('./components/ContactForm'));


const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    'scroll-margin-top': '64px'
  },
}));

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#0E4749",
    },
    secondary: {
      main: "#F05E0F",
    },
    background: {
      paper: "#002626",
      default: "#001414",
    },
    text: {
      primary: "rgba(239, 231, 218, 1)",
      secondary: "rgba(239, 231, 218, 0.85)",
      disabled: "rgba(239, 231, 218, 0.5)",
    },
    action: {
      active: "rgba(239, 231, 218, 1)",
      hover: "rgba(239, 231, 218, 0.08)",
      selected: "rgba(239, 231, 218, 0.16)",
      disabled: "rgba(239, 231, 218, 0.3)",
      disabledBackground: "rgba(239, 231, 218, 0.12)",
    }
  }
});

export default function App() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <div className={classes.root}>
          <CssBaseline />
          <Suspense fallback={<div></div>}>
            <Navbar onClick={handleDrawerOpen} />
            <Sidebar open={open} setOpen={setOpen} onDrawerClose={handleDrawerClose} onDrawerOpen={handleDrawerOpen} />
          </Suspense>
          <div className={classes.drawerHeader} />
          <Typography className={classes.header} variant="h4" id="about">About Xing Han Lu</Typography>
            <AboutMe />
          <Typography className={classes.header} variant="h4" id="experience">Experience</Typography>
          <Suspense fallback={<LinearProgress />}>
            <ExperienceTimeline />
          </Suspense>
          <Typography className={classes.header} variant="h4" id="research">Research</Typography>
          <ResearchIntro />
          <Suspense fallback={<LinearProgress />}>
            <TwitterDeck />
          </Suspense>
          <Typography className={classes.header} variant="h4" id="projects">Projects</Typography>
          <Suspense fallback={<LinearProgress />}>
            <ProjectSwipeView />
          </Suspense>
          <Typography className={classes.header} variant="h4" id="contact">Contact</Typography>
          <ContactIntro />
          <Suspense fallback={<LinearProgress />}>
            <ContactForm />
          </Suspense>
        </div>
      </Container>
    </ThemeProvider>
  );
}