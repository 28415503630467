import Typography from '@material-ui/core/Typography';

const ContactIntro = () => (
    <Typography variant="body1" paragraph>
        You can find various contact methods through the links on the appbar and sidebar. For professional inquiries,
        please contact me through LinkedIn. For projects, please contact me through GitHub. For anything else,
        you can reach out to me via Twitter or using the contact form below.
    </Typography>
)

export default ContactIntro;