import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const scholarUrl = "https://scholar.google.com/citations?user=ekoOv3YAAAAJ";
const ResearchIntro = () => (
    <Typography variant="body1" paragraph>
        You can find my publications on my <Link href={scholarUrl} color="secondary">Google Scholar page</Link> (link also the appbar and left sidebar).
        Here's a few projects I participated in, as seen through the lens of the Twitter research community:
    </Typography>
)

export default ResearchIntro;